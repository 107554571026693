import { useRouter } from "next/router"
import { useEffect, useRef, memo } from "react"
import { getAzureLocale } from "utils/getAzureLocale"
import { AZURE_MAPS_KEY } from "env"

// Worksite Map
export const JobDetailMap = memo(function JobDetailMap({ coords, atlasRef }) {
  const router = useRouter()
  const mapRef = useRef(null)
  const dataSourceRef = useRef(null)

  useEffect(() => {
    let whichAtlas = atlasRef
    if (typeof window !== "undefined" && !atlasRef) {
      whichAtlas = window.atlas
    }

    if (!mapRef.current && whichAtlas) {
      const language = getAzureLocale(router.locale)

      mapRef.current = new whichAtlas.Map("worksiteMap", {
        center: coords,
        zoom: 12,
        language: language,
        style: "grayscale_light",
        renderWorldCopies: false,
        showFeedbackLink: false,
        authOptions: {
          authType: "subscriptionKey",
          subscriptionKey: AZURE_MAPS_KEY,
        },
      })

      mapRef.current.controls.add(new whichAtlas.control.ZoomControl(), {
        position: "bottom-right",
      })

      // Wait for map to be ready before adding source and layer
      mapRef.current.events.add("ready", function () {
        // Create data source
        dataSourceRef.current = new whichAtlas.source.DataSource()
        mapRef.current.sources.add(dataSourceRef.current)

        // Add layer
        mapRef.current.layers.add(
          new whichAtlas.layer.SymbolLayer(dataSourceRef.current, null, {
            iconOptions: {
              image: "marker-red",
              size: 1.1,
              offset: [0, 0],
              opacity: 0.9,
            },
          })
        )

        // Add initial point
        const point = new whichAtlas.Shape(new whichAtlas.data.Point(coords))
        dataSourceRef.current.add([point])
      })
    }

    // Update point when coords change
    if (mapRef.current && dataSourceRef.current && whichAtlas) {
      mapRef.current.setCamera({
        center: coords,
        zoom: 12,
      })

      dataSourceRef.current.clear()
      const point = new whichAtlas.Shape(new whichAtlas.data.Point(coords))
      dataSourceRef.current.add([point])
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.dispose()
        mapRef.current = null
      }
      dataSourceRef.current = null
    }
  }, [coords, atlasRef])

  return (
    <div
      id="worksiteMap"
      className="w-full h-60 border-2 border-gray-200 my-3"
    />
  )
})
